import { ButtonSet, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from "carbon-components-react"
import Button from "../../../components/Button"
import { getOrderAddress, STATUS_AWAITING_DELIVERY, STATUS_AWAITING_PAYMENT_COLLECTION, STATUS_AWAITING_RENTAL_INSPECTION, STATUS_AWAITING_RENTAL_PICKUP, STATUS_DELIVERED, STATUS_FINALIZED, STATUS_OUT_FOR_DELIVERY, STATUS_OUT_FOR_PAYMENT_COLLECTION, STATUS_PENDING, STATUS_PICKED_UP, STATUS_PROCESSING, STATUS_PROCESSING_RENTAL, STATUS_RENTAL_OUT_FOR_PICKUP, STATUS_REST_AWAITING_FINALIZE, STATUS_REVOKED } from "../base/sales-order"
import { ErrorFilled16, CheckmarkFilled16, Save16, Van16, Money16, InProgress16, DeliveryTruck16, Currency16 } from '@carbon/icons-react'
import Api from "../../../session/Api"
import { memo, useEffect, useState } from "react"
import UIUtil from "../../../util/UIUtil"
import Util, { big } from "../../../util/Util"
import DateTimePicker from "../../../components/DateTimePicker"
import DriverAssignationDialog from "./DriverAssignationDialog"
import LocationPicker from "../../../components/LocationPicker"
import { Link } from "react-router-dom"
import FinalizeRentalInspectionDialog from "./FinalizeRentalInspectionDialog"
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR, TRANSACTION_DIRECTION_TYPE_INWARD } from "../../../constants/Constants"
import { OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_STOCK_FLOW } from "../../../constants/ObjectTypes"
import { TransactionPayButton } from "../../../views/transactions/transaction-pay-button"
import { hasCapabilitySupport } from "../../../app/Capabilities"
import SetDriverDialog from "./SetDriverDialog"
import { isRivas } from "../../../app/app-util"
import { getAccountRole } from "../../../session/SessionManager"
import { PaymentMethodDialog } from "../../pos/dialogs/payment-method-dialog"

const RevokeBtn = ({ salesOrder, onUpdateOrder, disabled, onLoadingChanged }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [reason, setReason] = useState("");
    useEffect(() => {
        onLoadingChanged(loading)
    }, [loading])
    useEffect(() => {
        if (visible) {
            setReason("")
        }
    }, [visible])
    const onRevokeBtn = () => {
        UIUtil.confirmMsg('This action is irreversible', () => {
            setLoading(true);
            Api.revokeSalesOrder(salesOrder.id, false, reason, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onUpdateOrder(response.payload);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        })
    }
    //return 
    return (
        <>
            <Button disabled={disabled} loading={loading} onClick={() => setVisible(true)} renderIcon={ErrorFilled16} kind="danger">Revoke</Button>

            <ComposedModal
                key="sales-order-revoke-reason-dialog"
                open={visible}
                onClose={() => setVisible(false)}
                size="sm">
                <ModalHeader label="Sales Order" title="Revoking" />
                <ModalBody style={{ paddingRight: '1rem' }}>
                    <TextArea
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                        labelText="Reason"
                        placeholder="Please state the reason for revoking order" />

                </ModalBody>
                <ModalFooter>
                    <ButtonSet style={{ width: '100%' }}>
                        <Button kind="secondary" onClick={() => setVisible(false)} renderIcon={ErrorFilled16} >
                            Cancel
                        </Button>
                        <Button onClick={onRevokeBtn} renderIcon={Save16} disabled={loading || !Util.isStringExists(reason)}
                        >
                            Save
                        </Button>
                    </ButtonSet>
                </ModalFooter>
            </ComposedModal>
        </>
    )
}

const Pending = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const [disabled, setDisabled] = useState(false);

    const [dateDialogVisible, setDateDialogVisible] = useState(false);
    const [targetDeliveryDate, setTargetDeliveryDate] = useState(salesOrder.preferredDeliveryDate);
    const [targetRentalPickupDate, setTargetRentalPickupDate] = useState(salesOrder.preferredRentalPickupDate);

    const [location, setLocation] = useState("");

    const onBtn = () => {
        setLoading(true);
        Api.confirmSalesOrderOrder(salesOrder.id, targetDeliveryDate, targetRentalPickupDate, location, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    // Api.revokeQuotation
    return (
        <>
            <RevokeBtn salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} disabled={loading} onLoadingChanged={setDisabled} />
            <Button disabled={disabled} loading={loading} renderIcon={CheckmarkFilled16} onClick={() => setDateDialogVisible(true)}>Confirm Order</Button>

            <ComposedModal open={dateDialogVisible} onClose={() => setDateDialogVisible(false)}>
                <ModalHeader label="Updating Status" title="Confirm Order" />
                <ModalBody>
                    <div style={{ display: 'flex', }}>
                        <div style={{ flex: 1 }}>
                            <h6>Preferred Delivery Date</h6>
                            <p>{Util.getFullDate(salesOrder.preferredDeliveryDate)}</p>
                        </div>
                        {salesOrder.requiresRentalPickup && salesOrder.preferredRentalPickupDate > 0 && <div style={{ flex: 1 }}>
                            <h6>Preferred Pickup Date</h6>
                            <p>{Util.getFullDate(salesOrder.preferredRentalPickupDate)}</p>
                        </div>}
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <h6 style={{ marginBottom: '0.25rem' }}>Target Delivery Date</h6>
                            <DateTimePicker defaultDate={targetDeliveryDate} onDateChanged={setTargetDeliveryDate} />
                        </div>
                        {salesOrder.requiresRentalPickup && <div style={{ flex: 1 }}>
                            <h6 style={{ marginBottom: '0.25rem' }}>Target Pickup Date</h6>
                            <DateTimePicker defaultDate={targetRentalPickupDate} onDateChanged={setTargetRentalPickupDate} />
                        </div>}
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <h6 style={{ marginBottom: '0.25rem' }}>Shipping Address</h6>
                        <p>{getOrderAddress(salesOrder)}</p>

                        <LocationPicker
                            location={location}
                            onLocationChange={setLocation}
                            label="Select shipping location (optional)" />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button kind="secondary" onClick={() => setDateDialogVisible(false)}>
                        Close
                    </Button>
                    <Button
                        loading={loading}
                        // disabled={!Util.isStringExists(location)}
                        onClick={onBtn}>
                        Confirm
                    </Button>

                </ModalFooter>
            </ComposedModal>
        </>
    )
}


const Processing = ({ salesOrder, onUpdateOrder }) => {
    const [disabled, setDisabled] = useState(false);
    const [assignVisibility, setAssignVisibility] = useState(false);

    return (
        <>
            <RevokeBtn salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} onLoadingChanged={setDisabled} />
            <Button disabled={disabled} renderIcon={Van16} onClick={() => setAssignVisibility(true)}>Assign Driver</Button>

            <DriverAssignationDialog
                salesOrderId={salesOrder.id}
                open={assignVisibility} onClose={() => setAssignVisibility(false)} onUpdateOrder={onUpdateOrder} />
        </>
    )
}

const SendOrderForDelivery = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const [assignVisibility, setAssignVisibility] = useState(false);

    const onBtn = (driverId) => {
        setLoading(true);
        Api.sendOrderForDelivery(salesOrder.id, driverId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
                setAssignVisibility(false)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <>
            <Button loading={loading} renderIcon={Van16} onClick={() => setAssignVisibility(true)}>Assign Driver</Button>
            <DriverAssignationDialog
                salesOrderId={salesOrder.id} label='Sending off for delivery' onButtonOverride={onBtn}
                open={assignVisibility} onClose={() => setAssignVisibility(false)} onUpdateOrder={onUpdateOrder} />
        </>
    )
}


const ProcessingAssignation = ({ salesOrder, onUpdateOrder, customText }) => {

    const [loading, setLoading] = useState(false);
    const onRevokeBtn = () => {
        UIUtil.confirmMsg('Are you sure? This action will unassign the current driver', () => {
            setLoading(true);
            Api.salesOrderRevokeDriverAssignation(salesOrder.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onUpdateOrder(response.payload);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        });
    }
    return <Button loading={loading} onClick={onRevokeBtn} renderIcon={ErrorFilled16} kind="danger">{customText ?? 'Cancel Driver Assignation'}</Button>;
}

const CollectionAssignation = ({ salesOrder, onUpdateOrder, customText }) => {
    const [loading, setLoading] = useState(false);
    const onRevokeBtn = () => {
        UIUtil.confirmMsg('Are you sure? This action will unassign the current driver', () => {
            setLoading(true);
            Api.salesOrderRevokeDriverAssignation(salesOrder.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onUpdateOrder(response.payload);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        });
    }
    return <Button loading={loading} onClick={onRevokeBtn} renderIcon={ErrorFilled16} kind="danger">{customText ?? 'Cancel Payment Collection Driver Assignation'}</Button>;
}

const RestDriverStatus = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const [assignVisibility, setAssignVisibility] = useState(false);

    const role = getAccountRole()
    const showDriverStatus = isRivas() && (role === ACCOUNT_TYPE_ADMINISTRATION)
    const canRevoke = salesOrder.currentAssignedDriverId <= 0;
    return (<>
        {/* <Button loading={loading} onClick={onRevokeBtn} renderIcon={ErrorFilled16} kind="danger">Cancel Driver Assignation</Button> */}
        <div style={{ paddingRight: '3rem', }}>
            <label style={{ marginBottom: 0 }} className="bx--label">Current Driver</label>
            <p style={{ marginBottom: '1rem' }}>{Util.isStringExists(salesOrder.assignedDriverName) ? salesOrder.assignedDriverName : "No driver"}</p>

            <div style={{ display: 'flex', gap: '0.15rem' }}>
                <Button loading={loading} renderIcon={Van16} onClick={() => setAssignVisibility(true)}>Change</Button>
                {canRevoke && <RevokeBtn salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} disabled={loading} onLoadingChanged={() => { }} />}
                {showDriverStatus && <DriverStatus salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} label="Cancel" />}
            </div>
        </div>


        <SetDriverDialog
            salesOrderId={salesOrder.id}
            open={assignVisibility} onClose={() => setAssignVisibility(false)} onUpdateOrder={onUpdateOrder} />
    </>);
}


const DriverStatus = ({ salesOrder, onUpdateOrder, label = "Cancel Driver Assignation" }) => {
    const [loading, setLoading] = useState(false);
    const onRevokeBtn = () => {
        UIUtil.confirmMsg('Are you sure? This action will change the status back to processing as well.', () => {
            setLoading(true);
            Api.salesOrderRevokeDriverAssignation(salesOrder.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onUpdateOrder(response.payload);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        });
    }
    return <Button loading={loading} onClick={onRevokeBtn} renderIcon={ErrorFilled16} kind="danger">{label}</Button>;
}

const ExternalAwaitingDelivery = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);

    const onDeliveredBtn = () => {
        UIUtil.confirmMsg('Are you sure? This action will change the status to delivered', () => {
            setLoading(true);
            Api.salesOrderExternalDelivered(salesOrder.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onUpdateOrder(response.payload);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        });
    }

    return <>
        {hasCapabilitySupport("restaurant") ? (
            <RestDriverStatus salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} />
        ) : (
            <DriverStatus salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} label="Back to Processing" />
        )}

        <Button loading={loading} onClick={onDeliveredBtn} renderIcon={DeliveryTruck16}>Order Delivered</Button>
    </>;
}

const ExternalDelivered = ({ salesOrder, onUpdateOrder }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const onConfirm = (paymentMethods) => {
        setLoading(true);
        Api.createSalesOrderStockFlow(salesOrder.id, paymentMethods, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
                setVisible(false);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    const onFinalizeBtn = () => {
        if (salesOrder.unpaidAmount > 0) {
            setVisible(true);
        } else {
            UIUtil.confirmMsg("This will create the sales for this order and finalize it. No payment is required for free order.", () => onConfirm([]))
        }
    }
    const onPaymentMethodConfirmed = async (paymentMethods) => {
        const amountPaid = (paymentMethods ?? []).map($ => big($.amount)).reduce((t, c) => t.add(c), big(0));
        const orderAmount = big(salesOrder.unpaidAmount);

        const notFullyPaid = orderAmount.gt(amountPaid);
        if (notFullyPaid) {
            const confirm = await UIUtil.confirmPrompt(salesOrder.hasCredit ? {
                message: "No payment method selected",
                positiveAction: "Use Customer Credit"
            } : {
                message: "No payment method selected. Credit not sufficient.",
                positiveAction: "Create Unpaid Sale"
            })
            if (confirm) {
                onConfirm(paymentMethods);
            }
        } else {
            onConfirm(paymentMethods);
        }
    }



    return (<>
        {hasCapabilitySupport("restaurant") ? (
            <RestDriverStatus salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} />
        ) : (
            <DriverStatus salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} label="Back to Processing" />
        )}

        <Button className="green-button" loading={loading} onClick={onFinalizeBtn} renderIcon={DeliveryTruck16}>Finalize Order</Button>

        <PaymentMethodDialog customerId={salesOrder.customerId} amount={salesOrder.unpaidAmount} open={visible} onClose={() => setVisible(false)} onConfirmBtn={onPaymentMethodConfirmed} loading={loading} />
    </>)
}

const CollectionDriverStatus = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onRevokeBtn = () => {
        UIUtil.confirmMsg('Are you sure? This action will change the status back to finalized as well.', () => {
            setLoading(true);
            Api.salesOrderRevokeDriverAssignation(salesOrder.id, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onUpdateOrder(response.payload);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        });
    }
    return <Button loading={loading} onClick={onRevokeBtn} renderIcon={ErrorFilled16} kind="danger">Cancel Payment Collection Driver Assignation</Button>;
}


const CanHavePaymentCollection = ({ salesOrder, onUpdateOrder }) => {
    const [assignVisibility, setAssignVisibility] = useState(false);

    return (
        <>
            <Button renderIcon={Money16} onClick={() => setAssignVisibility(true)}>Payment Collection</Button>
            {Util.isNumberExist(salesOrder.salesStockFlowId) && <TransactionPayButton
                amount={salesOrder.unpaidAmount} buttonProps={{ className: "green-button" }}
                direction={TRANSACTION_DIRECTION_TYPE_INWARD} itemId={salesOrder.salesStockFlowId} itemType={OBJECT_TYPE_STOCK_FLOW}
                partyItemType={OBJECT_TYPE_CUSTOMER} partyId={salesOrder.customerId} />}

            <DriverAssignationDialog
                salesOrderId={salesOrder.id}
                open={assignVisibility} onClose={() => setAssignVisibility(false)} onUpdateOrder={onUpdateOrder} />
        </>
    )
}


const AwaitingRentalInspection = ({ salesOrder, onUpdateOrder }) => {
    const [visible, setVisible] = useState(false);

    return (
        <>

            <div style={{ paddingRight: '3rem', }}>
                <p style={{ marginBottom: '-0.5rem' }}>Rental items picked up from customer</p>
                <br />
                <ul style={{ marginBottom: '1rem' }}>
                    <li style={{ marginBottom: '0.25rem' }}>
                        <label className="bx--label" style={{ margin: 0, marginRight: '0.25rem' }}>Pick-up time: </label>
                        {Util.getFullDate(salesOrder.dateRentalStockReturned)}
                    </li>

                    <li>
                        <label className="bx--label" style={{ margin: 0, marginRight: '0.25rem' }}>Driver: </label>
                        <Link target="_blank" to={"/staff-users/" + salesOrder.rentalStockReturnedToDriverId}>
                            {salesOrder.rentalStockReturnedToDriverName}
                        </Link>
                    </li>



                </ul>
                <Button renderIcon={InProgress16} onClick={() => setVisible(true)}>Finalize Inspection</Button>
            </div>

            <FinalizeRentalInspectionDialog
                salesOrderId={salesOrder.id}
                open={visible} onClose={() => setVisible(false)}
                onUpdateOrder={onUpdateOrder} />
        </>
    )
}


const Revoked = ({ salesOrder }) => (
    <div style={{ paddingRight: '3rem', }}>
        <label className="bx--label" style={{ margin: 0, marginBottom: '0.15rem' }}>Revoked by:</label>
        <br />
        {salesOrder.revokedByFullName}

        <br />
        <label className="bx--label" style={{ margin: 0, marginTop: '0.5rem', marginBottom: '0.15rem' }}>Reason: </label>
        <br />
        {salesOrder.revokedReason}
    </div>
)

export default ({ salesOrder, onUpdateOrder }) => {
    let Button;
    switch (salesOrder.status) {
        case STATUS_PENDING:
            if (!hasCapabilitySupport("restaurant")) {
                Button = Pending;
            }
            break;
        case STATUS_PROCESSING: case STATUS_PROCESSING_RENTAL:
            if (!hasCapabilitySupport("restaurant")) {
                if (Util.isNumberExist(salesOrder.currentAssignedDriverId)) {
                    Button = ProcessingAssignation;
                } else {
                    Button = Processing;
                }
            }
            break;
        case STATUS_AWAITING_DELIVERY: case STATUS_OUT_FOR_DELIVERY: case STATUS_DELIVERED:
        case STATUS_AWAITING_RENTAL_PICKUP: case STATUS_RENTAL_OUT_FOR_PICKUP: case STATUS_PICKED_UP:
            if (salesOrder.hasExternalDelivery) {
                if (salesOrder.status === STATUS_AWAITING_DELIVERY) {
                    Button = ExternalAwaitingDelivery;
                } else if (salesOrder.status === STATUS_DELIVERED) {
                    Button = ExternalDelivered;
                } else {
                    if (hasCapabilitySupport("restaurant")) {
                        Button = RestDriverStatus;
                    } else {
                        Button = DriverStatus;
                    }
                }
            } else {
                if (hasCapabilitySupport("restaurant")) {
                    Button = RestDriverStatus;
                } else {
                    Button = DriverStatus;
                }
            }
            break;
        case STATUS_FINALIZED:
            if (salesOrder.canHavePaymentCollection) {
                if (Util.isNumberExist(salesOrder.currentAssignedDriverId)) {
                    Button = CollectionAssignation;
                } else {
                    Button = CanHavePaymentCollection;
                }
            }
            break;
        case STATUS_AWAITING_PAYMENT_COLLECTION: case STATUS_OUT_FOR_PAYMENT_COLLECTION:
            Button = CollectionDriverStatus;
            break;
        case STATUS_AWAITING_RENTAL_INSPECTION:
            Button = AwaitingRentalInspection;
            break;
        case STATUS_REVOKED:
            Button = Revoked;
            break;
        case STATUS_REST_AWAITING_FINALIZE:
            Button = SendOrderForDelivery;
            break;

    }

    if (!Button) {
        return null;
    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'flex-start', paddingTop: '1rem', justifyContent: 'center', paddingRight: '1rem', gap: '0.15rem' }}>
                <Button salesOrder={salesOrder} onUpdateOrder={onUpdateOrder} />
            </div>
        </>
    )
}