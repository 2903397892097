import { Link as RouterLink, Route } from "react-router-dom";
import { getReportLink, listDetailNew, routeReport } from "../../reporting-engine/base/report-page";
import {
    AirlinePassengerCare16, List16, PassengerPlus16, Delivery16, ArrowRight16, Finance16, ServiceDesk16, Code16, Enterprise16, Archive16,
    Receipt16, AddAlt16, ShoppingBag16, Certificate16, ShoppingCartArrowUp16, Report16, Migrate16, ChartBullet16,
    FlowConnection16, GraphicalDataFlow16, Currency16, Notebook16, Money16, Table16, ArrowDown16, ArrowUp16, RequestQuote16,
    Api16, License16, DataShare16, PenFountain16, DataClass16, InventoryManagement16, Wallet16, FlowStream16, SubtractAlt16,
    MigrateAlt16, SettingsAdjust16, UserData16, Product16, Box16, Application16, Categories16, RulerAlt16, Types16,
    PhraseSentiment16, Network_116, Category16, CategoryNewEach16, Classification16, CutOut16, User16, Van16,
    WatsonHealthTextAnnotationToggle16, DocumentImport16, App16, Settings16, Gradient16,
    ManageProtection16,
    Home16,
    AirlineManageGates16,
    LicenseDraft16,
    MediaLibrary16,
    Blog16,
    ViewFilled16,
    Locked16,
    Bullhorn16,
    InsertPage16,
    Purchase16,
    GroupPresentation16,
    VideoFilled16,
    ListBoxes16,
    Education16, TreeView16, Security16, Cube16, Calculation16, TrashCan16,
    Calculation32,
    Activity16
} from '@carbon/icons-react'
// import { CustomerList } from "../modules/customers/pages/list";
import { NewSales } from "../modules/sales/pages/new-sales";
import { NewBill } from "../modules/bills/pages/new-bill";
import CRVPage from "../../pages/crv/CRVPage";
import CRVItemPage from "../../pages/crv/CRVItemPage";
import CRVCreatorPage from "../../pages/crv/CRVCreatorPage";
import TransactionCreatorPage from "../../pages/transaction/TransactionCreatorPage";
import TemplatesListPage from "../../pages/templates/TemplatesListPage";
import LabelDesignerPage from "../../pages/label-designer/LabelDesignerPage";
import ImportDataPage from "../../pages/import-data/ImportDataPage";
import ImportDataTypePage from "../../pages/import-data/ImportDataTypePage";
import CouponDetailPage from "../../pages/coupon/CouponDetailPage";
import CouponListPage from "../../pages/coupon/CouponListPage";
import SystemValuesPage from "../../pages/system-values/SystemValuesPage";
import SystemSettingsPage from "../../pages/system-settings/SystemSettingsPage";
import DeliveryAppPage from "../../pages/sales-app/DeliveryAppPage";
import MobileUnitDetailPage from "../../pages/mobile-unit/MobileUnitDetailPage";
import MobileUnitListPage from "../../pages/mobile-unit/MobileUnitListPage";
import ProductListPage from "../../pages/product/ProductListPage";
import ProductDetailPage from "../../pages/product/ProductDetailPage";
import ProductGroupListPage from "../../pages/product-group/ProductGroupListPage";
import ProductGroupDetailPage from "../../pages/product-group/ProductGroupDetailPage";
import ProductAttributesListPage from "../../pages/product-attributes/ProductAttributesListPage";
import ProductAttributesDetailPage from "../../pages/product-attributes/ProductAttributesDetailPage";
import ProductBundleListPage from "../../pages/product-bundle/ProductBundleListPage";
import ProductBundleDetailPage from "../../pages/product-bundle/ProductBundleDetailPage";
import ProductBrandListPage from "../../pages/product-brand/ProductBrandListPage";
import ProductBrandDetailPage from "../../pages/product-brand/ProductBrandDetailPage";
import { ProductClassificationsListPage } from "../../pages/product-classifications/list";
import { ProductClassificationsDetailPage } from "../../pages/product-classifications/detail";
import ProductDepartmentListPage from "../../pages/product-department/ProductDepartmentListPage";
import ProductDepartmentDetailPage from "../../pages/product-department/ProductDepartmentDetailPage";
import ProductCategoryListPage from "../../pages/product-category/ProductCategoryListPage";
import ProductCategoryDetailPage from "../../pages/product-category/ProductCategoryDetailPage";
import ProductSubcategoryListPage from "../../pages/product-subcategory/ProductSubcategoryListPage";
import ProductSubcategoryDetailPage from "../../pages/product-subcategory/ProductSubcategoryDetailPage";
import MeasurementTypeListPage from "../../pages/measurement-type/MeasurementTypeListPage";
import MeasurementTypeDetailPage from "../../pages/measurement-type/MeasurementTypeDetailPage";
import { LectureDetailPage } from "../../pages/learning/lecture/detail";
import { LectureListPage } from "../../pages/learning/lecture/list";
import { LectureVideoListPage } from "../../pages/learning/lecture-video/list";
import { LectureQuizListPage } from "../../pages/learning/lecture-quiz/list";
import { LectureQuizDetailPage } from "../../pages/learning/lecture-quiz/detail";
import { LectureVideoDetailPage } from "../../pages/learning/lecture-video/detail";
import BlogPostsListPage from "../../pages/blog/blog-posts/BlogPostsListPage";
import BlogPostsDetailPage from "../../pages/blog/blog-posts/BlogPostsDetailPage";
import BlogCategoriesListPage from "../../pages/blog/blog-categories/BlogCategoriesListPage";
import BlogCategoriesDetailPage from "../../pages/blog/blog-categories/BlogCategoriesDetailPage";
import DynamicContentPage from "../../pages/online-store/dynamic-content/DynamicContentPage";
import SlidesListPage from "../../pages/online-store/slides/SlidesListPage";
import SlidesDetailPage from "../../pages/online-store/slides/SlidesDetailPage";
import CustomPagesListPage from "../../pages/online-store/custom-pages/CustomPagesListPage";
import CustomPagesDetailPage from "../../pages/online-store/custom-pages/CustomPagesDetailPage";
import PrivateCustomPagesListPage from "../../pages/online-store/private-custom-pages/PrivateCustomPagesListPage";
import PrivateCustomPagesDetailPage from "../../pages/online-store/private-custom-pages/PrivateCustomPagesDetailPage";
import OnlineStorePaymentMethodsListPage from "../../pages/online-store/online-store-payment-methods/OnlineStorePaymentMethodsListPage";
import OnlineStorePaymentMethodsDetailPage from "../../pages/online-store/online-store-payment-methods/OnlineStorePaymentMethodsDetailPage";
import NewsletterSubscriptionListPage from "../../pages/newsletter-subscription/NewsletterSubscriptionListPage";
import PageHeadListPage from "../../pages/online-store/page-heads/PageHeadListPage";
import PageHeadDetailPage from "../../pages/online-store/page-heads/PageHeadDetailPage";
import GalleryPostsDetailPage from "../../pages/gallery-posts/GalleryPostsDetailPage";
import GalleryPostsListPage from "../../pages/gallery-posts/GalleryPostsListPage";
import TestimonyPostsListPage from "../../pages/testimony-posts/TestimonyPostsListPage";
import TestimonyPostsDetailPage from "../../pages/testimony-posts/TestimonyPostsDetailPage";
import { OnlineStoreAdminView } from "../../pages/online-store/admin-view";
import AccountLedgerGroupsListPage from "../../pages/accounting/account-ledger-groups/AccountLedgerGroupsListPage";
import AccountLedgerGroupsDetailPage from "../../pages/accounting/account-ledger-groups/AccountLedgerGroupsDetailPage";
import AccountLedgersListPage from "../../pages/accounting/account-ledgers/AccountLedgersListPage";
import AccountLedgersDetailPage from "../../pages/accounting/account-ledgers/AccountLedgersDetailPage";
import { PredefinedClassesDetailPage } from "../../pages/predefined-classes/detail";
import { PredefinedClassesListPage } from "../../pages/predefined-classes/list";
import StatementOfAccountsPage from "../../pages/statement-of-accounts/StatementOfAccountsPage";
import { FundTransferDetailPage } from "../../pages/fund-transfer/detail";
import JournalEntriesPage from "../../pages/journal-entries/JournalEntriesPage";
import JournalEntriesCreatorPage from "../../pages/journal-entries/JournalEntriesCreatorPage";
import JournalEntriesItemPage from "../../pages/journal-entries/JournalEntriesItemPage";
import ChequeDetailPage from "../../pages/cheque/ChequeDetailPage";
import ChequeListPage from "../../pages/cheque/ChequeListPage";
import StatementReportPage from '../../pages/accounting/statement-reports/StatementReportPage';
import { ACCOUNTING_STATEMENT_BALANCE_SHEET, ACCOUNTING_STATEMENT_INCOME_SHEET, ACCOUNTING_STATEMENT_TRIAL_BALANCE } from "../../constants/Constants";
import SupplierListPage from "../../pages/supplier/SupplierListPage";
import SupplierDetailPage from "../../pages/supplier/SupplierDetailPage";
import StockFlowsPage from "../../pages/stock-flow/StockFlowsPage";
import DeliverablesPage from "../../pages/sales-order/DeliverablesPage";
import DeliverableItemPage from "../../pages/sales-order/DeliverableItemPage";
import DashboardPage from "../../pages/dashboard/DashboardPage";
import UserListPage from "../../pages/user/UserListPage";
import UserDetailPage from "../../pages/user/UserDetailPage";
import CustomerDetailPage from "../../pages/customer/CustomerDetailPage";
import CustomerListPage from "../../pages/customer/CustomerListPage";
import TransactionsPage from "../../pages/transaction/TransactionsPage";
import TransactionItemPage from "../../pages/transaction/TransactionItemPage";
import StockFlowItemPage from "../../pages/stock-flow/StockFlowItemPage";
import StockFlowCreatorPage from "../../pages/stock-flow/StockFlowCreatorPage";
import WelcomePage from "../../pages/message-pages/WelcomePage";
import { newListDetail } from "../../reporting-engine/base/list-detail";
import { CogsCalculator } from "../../pages/cogs-calculator";

const Link = ({ ...props }) => <RouterLink {...props} style={{ textDecoration: 'none' }} />

const DropdownBtn = ({ to, Icon, children }) => {
    if (to) {
        return (
            <Link to={to}>
                <button style={{}} className="menu-dropdown-button"><Icon /> <span style={{ flex: 1, textAlign: 'start' }}>{children}</span> <ArrowRight16 className="right-arrow" /></button>
            </Link>
        )
    } else {
        return (
            <button style={{ opacity: true ? 0.25 : 1 }} className="menu-dropdown-button"><Icon /> <span style={{ flex: 1, textAlign: 'start' }}>{children}</span> <ArrowRight16 className="right-arrow" /></button>
        )
    }
}

const crudRoute = (path, list, detail) => ([
    <Route exact path={`/${path}`} component={list} />,
    <Route exact path={`/${path}/:itemId`} component={detail} />
])

export const ADMIN = {
    router: [
        // <Route exact path="/" component={DashboardPage} />,
        <Route exact path="/" component={WelcomePage} />,

        // <Route exact path="/balance-sheet" key="balance-sheet" render={(props) => <StatementReportPage {...props} statementType={ACCOUNTING_STATEMENT_INCOME_SHEET} />} />,

        // <Route exact path="/customers" component={CustomerList} />,
        <Route exact path="/customers" component={CustomerListPage} />,
        <Route exact path="/customers/:itemId" component={CustomerDetailPage} />,


        // <Route exact path="/new-sales" component={NewSales} />,

        <Route exact path="/coupons" component={CouponListPage} />,
        <Route exact path="/coupons/:itemId" component={CouponDetailPage} />,

        routeReport("SalesByCustomerSummary"),
        routeReport("SalesByCustomerDetail"),
        routeReport("SalesByItemSummary"),
        routeReport("SalesByCustomerDetailPerItem"),

        routeReport("CustomerBalanceSummary"),
        routeReport("CustomerBalanceDetail"),
        routeReport("CustomerAgingSummary"),
        routeReport("SalesByCustomerType"),
        routeReport("SalesOrderQuantity"),
        routeReport("SalesOrderValuation"),
        routeReport("SalesByItemDetail"),



        // <Route exact path="/sales" key="sales-stock-flow-list" render={(props) => <StockFlowsPage {...props} key="sale-stock-flow-list-filter-force" forceSalesFilter />} />,
        routeReport("V2SalesList"),
        routeReport("V2SalesReport"),
        routeReport("ConsumptionByService"),
        routeReport("SalesReportDetail"),
        routeReport("SalesConsumptionDetail"),

        <Route exact path="/deliverables" component={DeliverablesPage} />,
        <Route exact path="/deliverables/:itemId" component={DeliverableItemPage} />,


        // <Route exact path="/suppliers" component={SupplierListPage} />,
        // <Route exact path="/suppliers/:itemId" component={SupplierDetailPage} />,
        // <Route exact path="/new-bill" component={NewBill} />,
        // <Route exact path="/bills" key="bills-stock-flow-list" render={(props) => <StockFlowsPage {...props} key="purchase-stock-flow-list-filter-force" forcePurchaseFilter />} />,
        // routeReport("PurchaseReport"),
        routeReport("V2BillsReport"),



        //Reports
        routeReport("BillsBySupplierSummary"),
        routeReport("BillsBySupplierDetail"),
        routeReport("BillsBySupplierPerItem"),
        routeReport("BillsByItemSummary"),
        routeReport("BillsByItemDetail"),
        routeReport("SupplierBalanceSummary"),
        routeReport("SupplierBalanceDetail"),
        routeReport("SupplierAgingSummary"),
        routeReport("StockByLocation"),
        routeReport("InvValuationSummary"),
        routeReport("InvValuationDetail"),
        routeReport("StockStatusByItem"),
        routeReport("ConsumptionReport"),
        // routeReport("ConsumptionByLocation"),
        routeReport("BalanceSheet"),
        routeReport("ProfitLoss"),
        routeReport("TrialBalance"),
        routeReport("AccountTree"),
        routeReport("VATSummary"),
        routeReport("VATDetail"),

        routeReport("CostingAudit"),
        routeReport("V2StockReport"),
        routeReport("PhysicalInventoryWorksheet"),

        // routeReport("StockQtyByLocReport"),
        // routeReport("StockValuationReport"),
        // routeReport("StockValuationByLocReport"),


        // routeReport("StockReport"),
        // routeReport("ProductStockReport"),
        // <Route exact path="/stock-flow/:stockFlowId" component={StockFlowItemPage} />,
        // <Route exact path="/new-stock-transfer" key="new-stock-transfer" render={(props) => <StockFlowCreatorPage {...props} transferOnly />} />,
        // <Route exact path="/new-stock-creation" key="new-stock-creation" render={(props) => <StockFlowCreatorPage {...props} creationOnly />} />,
        // <Route exact path="/new-stock-termination" key="new-stock-termination" render={(props) => <StockFlowCreatorPage {...props} terminationOnly />} />,
        // <Route exact path="/stock-flows" key="flow-stock-flow-list" render={(props) => <StockFlowsPage {...props} key="purchase-stock-flow-list-filter-force" />} />,
        routeReport("PurchaseOrderList"),
        routeReport("V2BillList"),
        routeReport("StockAdjustmentList"),
        routeReport("StockTransferList"),
        // routeReport("StockWasteList"),


        <Route exact path="/account-ledger-groups" component={AccountLedgerGroupsListPage} />,
        <Route exact path="/account-ledger-groups/:itemId" component={AccountLedgerGroupsDetailPage} />,

        <Route exact path="/account-ledgers" component={AccountLedgersListPage} />,
        <Route exact path="/account-ledgers/:itemId" component={AccountLedgersDetailPage} />,

        ...crudRoute("predefined-classes", PredefinedClassesListPage, PredefinedClassesDetailPage),

        // <Route exact path="/balance-sheet" key="balance-sheet" render={(props) => <StatementReportPage {...props} statementType={ACCOUNTING_STATEMENT_BALANCE_SHEET} />} />,
        // <Route exact path="/profit-and-loss-statement" key="income-statement" render={(props) => <StatementReportPage {...props} statementType={ACCOUNTING_STATEMENT_INCOME_SHEET} />} />,
        // <Route exact path="/trial-balance" key="trial-balance" render={(props) => <StatementReportPage {...props} statementType={ACCOUNTING_STATEMENT_TRIAL_BALANCE} />} />,

        <Route exact path="/statement-of-account" component={StatementOfAccountsPage} />,

        routeReport("FundTransferList"),
        <Route exact path="/fund-transfer/:fundTransferId" component={FundTransferDetailPage} />,

        <Route exact path="/journal-entries" component={JournalEntriesPage} />,
        <Route exact path="/journal-entry-creator" component={JournalEntriesCreatorPage} />,
        <Route exact path="/journal-entry/:journalEntryId" component={JournalEntriesItemPage} />,

        <Route exact path="/cheques" component={ChequeListPage} />,
        <Route exact path="/cheques/:itemId" component={ChequeDetailPage} />,

        routeReport("LedgerReport"),
        routeReport("V2LedgerReport"),
        routeReport("AccountingEntitiesReport"),








        <Route exact path="/transactions" component={TransactionsPage} />,
        <Route exact path="/transaction/:transactionId" component={TransactionItemPage} />,
        <Route exact path="/transaction-creator" component={TransactionCreatorPage} />,
        <Route exact path="/receipt-voucher-creator" key="receipt-voucher-creator" render={(props) => <TransactionCreatorPage {...props} receiptVoucher />} />,
        <Route exact path="/payment-voucher-creator" key="payment-voucher-creator" render={(props) => <TransactionCreatorPage {...props} paymentVoucher />} />,

        <Route exact path="/cash-receipt-vouchers" component={CRVPage} />,
        <Route exact path="/crv-creator" component={CRVCreatorPage} />,
        <Route exact path="/crv/:itemId" component={CRVItemPage} />,
        routeReport("CRVSummary"),
        routeReport("CRVDetail"),
        routeReport("CRVAccountsBalance"),




        routeReport("OnlineLearnersList"),
        ...crudRoute("lectures", LectureListPage, LectureDetailPage),
        ...crudRoute("lecture-videos", LectureVideoListPage, LectureVideoDetailPage),
        ...crudRoute("lecture-quizzes", LectureQuizListPage, LectureQuizDetailPage),


        <Route exact path="/website-admin-view" component={OnlineStoreAdminView} />,
        ...crudRoute("page-heads", PageHeadListPage, PageHeadDetailPage),
        ...crudRoute("gallery-posts", GalleryPostsListPage, GalleryPostsDetailPage),
        ...crudRoute("customer-testimonies", TestimonyPostsListPage, TestimonyPostsDetailPage),
        <Route exact path="/dynamic-content" component={DynamicContentPage} />,

        <Route exact path="/slides" component={SlidesListPage} />,
        <Route exact path="/slides/:itemId" component={SlidesDetailPage} />,

        <Route exact path="/online-store-custom-pages" component={CustomPagesListPage} />,
        <Route exact path="/online-store-custom-pages/:itemId" component={CustomPagesDetailPage} />,

        <Route exact path="/private-online-store-custom-pages" component={PrivateCustomPagesListPage} />,
        <Route exact path="/private-online-store-custom-pages/:itemId" component={PrivateCustomPagesDetailPage} />,

        <Route exact path="/online-store-payment-methods" component={OnlineStorePaymentMethodsListPage} />,
        <Route exact path="/online-store-payment-methods/:itemId" component={OnlineStorePaymentMethodsDetailPage} />,

        <Route exact path="/newsletter-subscriptions" component={NewsletterSubscriptionListPage} />,

        <Route exact path="/blog-posts" component={BlogPostsListPage} />,
        <Route exact path="/blog-posts/:itemId" component={BlogPostsDetailPage} />,

        <Route exact path="/blog-categories" component={BlogCategoriesListPage} />,
        <Route exact path="/blog-categories/:itemId" component={BlogCategoriesDetailPage} />,



        routeReport("ProductList"),
        <Route exact path="/products" component={ProductListPage} />,
        <Route exact path="/products/:itemId" component={ProductDetailPage} />,

        <Route exact path="/product-groups" component={ProductGroupListPage} />,
        <Route exact path="/product-groups/:itemId" component={ProductGroupDetailPage} />,

        <Route exact path="/product-bundles" component={ProductBundleListPage} />,
        <Route exact path="/product-bundles/:itemId" component={ProductBundleDetailPage} />,

        <Route exact path="/product-brands" component={ProductBrandListPage} />,
        <Route exact path="/product-brands/:itemId" component={ProductBrandDetailPage} />,

        <Route exact path="/product-classifications" component={ProductClassificationsListPage} />,
        <Route exact path="/product-classifications/:itemId" component={ProductClassificationsDetailPage} />,

        <Route exact path="/product-departments" component={ProductDepartmentListPage} />,
        <Route exact path="/product-departments/:itemId" component={ProductDepartmentDetailPage} />,

        <Route exact path="/product-categories" component={ProductCategoryListPage} />,
        <Route exact path="/product-categories/:itemId" component={ProductCategoryDetailPage} />,

        <Route exact path="/product-subcategories" component={ProductSubcategoryListPage} />,
        <Route exact path="/product-subcategories/:itemId" component={ProductSubcategoryDetailPage} />,

        <Route exact path="/measurement-types" component={MeasurementTypeListPage} />,
        <Route exact path="/measurement-types/:itemId" component={MeasurementTypeDetailPage} />,



        <Route exact path="/mobile-units" component={MobileUnitListPage} />,
        <Route exact path="/mobile-units/:itemId" component={MobileUnitDetailPage} />,


        <Route exact path="/templates" component={TemplatesListPage} />,
        <Route exact path="/template-designer/:templateId" component={LabelDesignerPage} />,

        <Route exact path="/import-data" component={ImportDataPage} />,
        <Route exact path="/import-data/:objectType" component={ImportDataTypePage} />,

        <Route exact path="/system-values" component={SystemValuesPage} />,
        <Route exact path="/system-settings" component={SystemSettingsPage} />,
        <Route exact path="/delivery-app" component={DeliveryAppPage} />,
        routeReport("SystemLogsReport"),

        <Route exact path="/staff-users" component={UserListPage} />,
        <Route exact path="/staff-users/:itemId" component={UserDetailPage} />,


        routeReport("CustomerList"),
        routeReport("VendorList"),
        routeReport("EmployeeList"),
        routeReport("CustomerTypeList"),
        routeReport("InventoryLocationList"),

        routeReport("BookClosings"),

        <Route exact path="/cogs-calculator" component={CogsCalculator} />,
    ],
    menu: [
        {
            id: 'customers',
            button: <button className="menu-button"><AirlinePassengerCare16 /> Customers</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Quick Actions</h6>
                        <DropdownBtn to={listDetailNew("V2SalesList")} Icon={Receipt16}>Create Sale</DropdownBtn>
                        <DropdownBtn to="/receipt-voucher-creator" Icon={Currency16}>Receive Payment</DropdownBtn>

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Management</h6>
                        <DropdownBtn to={"/deliverables"} Icon={ShoppingBag16}>Delivery Orders</DropdownBtn>
                        <DropdownBtn to={"/sales"} Icon={Certificate16}>Sales/Invoices</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("CustomerList")} Icon={AirlinePassengerCare16}>Customers</DropdownBtn>
                        <DropdownBtn to={getReportLink("CustomerTypeList")} Icon={Types16}>Customer Types</DropdownBtn>
                        <DropdownBtn to={"/coupons"} Icon={CutOut16}>Coupons</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Reporting</h6>

                        <DropdownBtn to={getReportLink("V2SalesReport")} Icon={Report16}>Sales Report</DropdownBtn>
                        <DropdownBtn to={getReportLink("SalesReportDetail")} Icon={Report16}>Sales Report Detail</DropdownBtn>

                        <br />
                        <DropdownBtn to={getReportLink("SalesByCustomerType")} Icon={Report16}>Sales by Customer Type</DropdownBtn>
                        <DropdownBtn to={getReportLink("SalesByCustomerSummary")} Icon={Report16}>Sales by Customer Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("SalesByCustomerDetail")} Icon={Report16}>Sales by Customer Detail</DropdownBtn>
                        <DropdownBtn to={getReportLink("SalesByCustomerDetailPerItem")} Icon={Report16}>Sales by Customer Detail per Item</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("SalesByItemSummary")} Icon={Report16}>Sales by Item Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("SalesByItemDetail")} Icon={Report16}>Sales by Item Detail</DropdownBtn>

                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', opacity: 0 }}>Reporting</h6>


                        <DropdownBtn to={getReportLink("SalesOrderQuantity")} Icon={Report16}>Orders quantity</DropdownBtn>
                        <DropdownBtn to={getReportLink("SalesOrderValuation")} Icon={Report16}>Orders valuation</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("CustomerBalanceSummary")} Icon={Report16}>Balance Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("CustomerBalanceDetail")} Icon={Report16}>Balance Detail</DropdownBtn>
                        <DropdownBtn to={getReportLink("CustomerAgingSummary")} Icon={Report16}>Aging Summary</DropdownBtn>

                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Custom Reports</h6>

                        <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>
                    </div>
                </div>
            )
        },
        {
            id: "suppliers",
            button: <button className="menu-button"><Delivery16 /> Vendors</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Quick Actions</h6>
                        <DropdownBtn to="/payment-voucher-creator" Icon={Currency16}>Pay Bill</DropdownBtn>
                        <DropdownBtn to={listDetailNew("PurchaseOrderList")} Icon={GraphicalDataFlow16}>Create PO</DropdownBtn>
                        <DropdownBtn to={listDetailNew("V2BillList")} Icon={Migrate16}>Create Bill</DropdownBtn>
                        <DropdownBtn to={listDetailNew("VendorList")} Icon={AddAlt16}>New Vendor</DropdownBtn>


                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Management</h6>
                        <DropdownBtn to={getReportLink("VendorList")} Icon={Delivery16}>Vendors</DropdownBtn>
                        <DropdownBtn to={getReportLink("V2BillList")} Icon={ChartBullet16}>Bills</DropdownBtn>
                        <DropdownBtn to={getReportLink("PurchaseOrderList")} Icon={FlowConnection16}>POs</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Reporting</h6>
                        <DropdownBtn to={getReportLink("V2BillsReport")} Icon={Report16}>Bills Report</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("BillsBySupplierSummary")} Icon={Report16}>Bills by Vendor Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("BillsBySupplierDetail")} Icon={Report16}>Bills by Vendor Detail</DropdownBtn>
                        <DropdownBtn to={getReportLink("BillsBySupplierPerItem")} Icon={Report16}>Bills by Vendor Detail per Item</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("BillsByItemSummary")} Icon={Report16}>Bills by Item Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("BillsByItemDetail")} Icon={Report16}>Bills by Item Detail</DropdownBtn>


                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', opacity: 0 }}>Reporting</h6>

                        <DropdownBtn to={getReportLink("SupplierBalanceSummary")} Icon={Report16}>Balance Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("SupplierBalanceDetail")} Icon={Report16}>Balance Detail</DropdownBtn>
                        <DropdownBtn to={getReportLink("SupplierAgingSummary")} Icon={Report16}>Aging Summary</DropdownBtn>

                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Custom Reports</h6>

                        <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>
                    </div>
                </div>
            )
        },
        {
            id: "inventory",
            button: <button className="menu-button"><InventoryManagement16 /> Inventory</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Quick Actions</h6>
                        <DropdownBtn to={listDetailNew("StockTransferList")} Icon={MigrateAlt16}>Transfer Stock</DropdownBtn>
                        <DropdownBtn to={listDetailNew("StockAdjustmentList")} Icon={Calculation16}>Adjust Stock</DropdownBtn>
                        {/* <DropdownBtn to={listDetailNew("StockWasteList")} Icon={SubtractAlt16}>Waste Stock</DropdownBtn> */}


                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Inventory Management</h6>
                        <DropdownBtn to={getReportLink("StockTransferList")} Icon={FlowStream16}>Stock Transfers</DropdownBtn>
                        <DropdownBtn to={getReportLink("StockAdjustmentList")} Icon={Calculation16}>Stock Adjustments</DropdownBtn>
                        {/* <DropdownBtn to={getReportLink("StockWasteList")} Icon={TrashCan16}>Stock Waste</DropdownBtn> */}
                        <br />
                        <DropdownBtn to={getReportLink("InventoryLocationList")} Icon={Cube16}>Stock Locations</DropdownBtn>

                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Reporting</h6>
                        <DropdownBtn to={getReportLink("V2StockReport")} Icon={Report16}>Stock Report</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("StockByLocation")} Icon={Report16}>Stock by Location</DropdownBtn>
                        <DropdownBtn to={getReportLink("InvValuationSummary")} Icon={Report16}>Valuation Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("InvValuationDetail")} Icon={Report16}>Valuation Detail</DropdownBtn>

                        {/* <DropdownBtn to={getReportLink("StockQtyByLocReport")} Icon={Report16}>Stock by Location</DropdownBtn>
                        <DropdownBtn to={getReportLink("StockValuationReport")} Icon={Report16}>Valuation Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("StockValuationByLocReport")} Icon={Report16}>Valuation Detail</DropdownBtn> */}
                        <br />

                        <DropdownBtn to={getReportLink("CostingAudit")} Icon={SettingsAdjust16}>Costing Audit</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', opacity: 0 }}>Reporting</h6>
                        <DropdownBtn to={getReportLink("StockStatusByItem")} Icon={Report16}>Stock Status by Item</DropdownBtn>
                        <DropdownBtn to={getReportLink("PhysicalInventoryWorksheet")} Icon={Report16}>Physical Inventory Worksheet</DropdownBtn>

                        <br />
                        <DropdownBtn to={getReportLink("ConsumptionReport")} Icon={Report16}>Consumption</DropdownBtn>
                        <DropdownBtn to={getReportLink("ConsumptionByService")} Icon={Report16}>Consumption by Service</DropdownBtn>

                        <br />
                        <DropdownBtn to={getReportLink("SalesConsumptionDetail")} Icon={Report16}>Sales Consumption Detail</DropdownBtn>


                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Tools</h6>

                        <DropdownBtn to={"/cogs-calculator"} Icon={Activity16}>COGS Calculator</DropdownBtn>
                    </div>
                </div>
            )
        },
        {
            id: "finances",
            button: <button className="menu-button"><Finance16 /> Finances</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Statements</h6>
                        <DropdownBtn to={getReportLink("BalanceSheet")} Icon={Notebook16}>Balance Sheet</DropdownBtn>
                        <DropdownBtn to={getReportLink("ProfitLoss")} Icon={Money16}>Profit & Loss</DropdownBtn>
                        <DropdownBtn to={getReportLink("TrialBalance")} Icon={Table16}>Trial Balance</DropdownBtn>
                        <DropdownBtn to="/statement-of-account" Icon={UserData16}>Statement of Account</DropdownBtn>

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Vouchers</h6>
                        <DropdownBtn to="/transactions" Icon={Currency16}>Transactions</DropdownBtn>
                        <DropdownBtn to="/payment-voucher-creator" Icon={ArrowDown16}>New Payment Voucher</DropdownBtn>
                        <DropdownBtn to="/receipt-voucher-creator" Icon={ArrowUp16}>New Receipt Voucher</DropdownBtn>
                        <DropdownBtn to="/cheques" Icon={PenFountain16}>Cheques</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', marginTop: '0rem' }}>Overview</h6>
                        <DropdownBtn to={getReportLink("AccountTree")} Icon={TreeView16}>Account Tree</DropdownBtn>
                        {/* <DropdownBtn to={getReportLink("AccountingEntitiesReport")} Icon={Report16}>Accounting Entities</DropdownBtn> */}

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Accounting</h6>
                        <DropdownBtn to="/journal-entries" Icon={RequestQuote16}>Journal Entries</DropdownBtn>
                        <DropdownBtn to={getReportLink("FundTransferList")} Icon={DataShare16}>Fund Transfers</DropdownBtn>
                        <DropdownBtn to={getReportLink("BookClosings")} Icon={License16}>Book Closings</DropdownBtn>


                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>CRV</h6>
                        <DropdownBtn to="/cash-receipt-vouchers" Icon={Wallet16}>Cash Receipt Vouchers</DropdownBtn>
                        <DropdownBtn to={getReportLink("CRVSummary")} Icon={Report16}>CRV Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("CRVDetail")} Icon={Report16}>CRV Detail</DropdownBtn>
                        {/* <br />
                        <DropdownBtn to={getReportLink("CRVAccountsBalance")} Icon={Report16}>CRV Accounts Balance</DropdownBtn> */}
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', opacity: 1 }}>Reporting</h6>
                        <DropdownBtn to={getReportLink("V2LedgerReport")} Icon={Report16}>General Ledger Report</DropdownBtn>
                        {/* <DropdownBtn to={getReportLink("LedgerReport")} Icon={Report16}>Ledger Report</DropdownBtn> */}

                        <br />
                        <DropdownBtn to={getReportLink("VATSummary")} Icon={Report16}>VAT Summary</DropdownBtn>
                        <DropdownBtn to={getReportLink("VATDetail")} Icon={Report16}>VAT Detail</DropdownBtn>

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Definitions</h6>
                        <DropdownBtn to={"/account-ledger-groups"} Icon={Api16}>Ledger Groups</DropdownBtn>
                        <DropdownBtn to={"/account-ledgers"} Icon={License16}>Account Ledgers</DropdownBtn>
                        <DropdownBtn to={"/predefined-classes"} Icon={DataClass16}>Predefined Classes</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Custom Reports</h6>

                        <p style={{ fontSize: 12, opacity: 0.65 }}>No items</p>
                    </div>
                </div>
            )
        },
        {
            id: "website",
            button: <button className="menu-button"><Code16 /> Website</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', marginTop: '0rem', }}>CMS</h6>
                        <DropdownBtn to="/website-admin-view" Icon={ManageProtection16}>Visit As Admin</DropdownBtn>
                        <br />
                        {/* <DropdownBtn to="/slides" Icon={Home16}>Home Page</DropdownBtn> */}
                        <DropdownBtn to="/customer-testimonies" Icon={AirlineManageGates16}>Customer Testimonies</DropdownBtn>
                        <DropdownBtn to="/dynamic-content" Icon={LicenseDraft16}>Dynamic Content</DropdownBtn>
                        <DropdownBtn to="/page-heads" Icon={Code16}>Page Heads</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', }}>Posting</h6>
                        <DropdownBtn to="/gallery-posts" Icon={MediaLibrary16}>Gallery Posts</DropdownBtn>
                        <DropdownBtn to="/blog-posts" Icon={Blog16}>Blog Posts</DropdownBtn>

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem', }}>Paging</h6>
                        <DropdownBtn to="/online-store-custom-pages" Icon={ViewFilled16}>Public Pages</DropdownBtn>
                        <DropdownBtn to="/private-online-store-custom-pages" Icon={Locked16}>Private Pages</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', opacity: 1 }}>Users</h6>
                        <DropdownBtn to="/customers" Icon={AirlinePassengerCare16}>Customer Users</DropdownBtn>
                        <DropdownBtn to="/newsletter-subscriptions" Icon={Bullhorn16}>Newsletter</DropdownBtn>

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem', opacity: 1 }}>Definitions</h6>
                        <DropdownBtn to="/blog-categories" Icon={InsertPage16}>Blog Categories</DropdownBtn>
                        <DropdownBtn to="/online-store-payment-methods" Icon={Purchase16}>Payment Methods</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Learning</h6>
                        <DropdownBtn to="/lectures" Icon={GroupPresentation16}>Courses</DropdownBtn>
                        <DropdownBtn to="/lecture-videos" Icon={VideoFilled16}>Videos</DropdownBtn>
                        <DropdownBtn to="/lecture-quizzes" Icon={ListBoxes16}>Quizzes</DropdownBtn>

                        <br />
                        <DropdownBtn to={getReportLink("OnlineLearnersList")} Icon={Education16}>Learners</DropdownBtn>
                    </div>
                </div>
            )
        },
        {
            id: "company",
            button: <button className="menu-button"><Enterprise16 /> Company</button>,
            dropdown: props => (
                <div style={{ display: 'flex', gap: '3rem' }}>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Offerings</h6>
                        <DropdownBtn to={"/product-bundles"} Icon={Application16}>Services</DropdownBtn>
                        <DropdownBtn to={getReportLink("ProductList")} Icon={Product16}>Products</DropdownBtn>
                        {/* <DropdownBtn Icon={Box16}>Bundles</DropdownBtn> */}
                        <br />
                        <DropdownBtn to="/product-groups" Icon={Categories16}>Groups</DropdownBtn>
                        <DropdownBtn to="/product-classifications" Icon={Classification16}>Classifications</DropdownBtn>
                        <DropdownBtn to="/measurement-types" Icon={RulerAlt16}>Measurement Types</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', marginTop: '0rem', opacity: 0 }}>Offerings</h6>

                        <DropdownBtn to="/product-brands" Icon={PhraseSentiment16}>Brands</DropdownBtn>
                        <DropdownBtn to="/product-departments" Icon={Network_116}>Departments</DropdownBtn>
                        <DropdownBtn to="/product-categories" Icon={Category16}>Categories</DropdownBtn>
                        <DropdownBtn to="/product-subcategories" Icon={CategoryNewEach16}>Subcategories</DropdownBtn>
                        {/* <br /> */}

                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem', opacity: 1 }}>Internal</h6>

                        <DropdownBtn to={getReportLink("EmployeeList")} Icon={User16}>Employees</DropdownBtn>
                        <DropdownBtn to="/mobile-units" Icon={Van16}>Mobile Units</DropdownBtn>
                        <DropdownBtn to="/system-values" Icon={WatsonHealthTextAnnotationToggle16}>Values</DropdownBtn>

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>Apps</h6>
                        <DropdownBtn to="/delivery-app" Icon={App16}>Delivery App</DropdownBtn>
                    </div>
                    <div style={{ flex: 1 }}>
                        <h6 style={{ marginBottom: '1rem' }}>Tools</h6>
                        <DropdownBtn to="/templates" Icon={Gradient16}>Template Designer</DropdownBtn>
                        <DropdownBtn to="/import-data" Icon={DocumentImport16}>Import Data</DropdownBtn>

                        <h6 style={{ marginBottom: '1rem', marginTop: '1rem' }}>System</h6>
                        <DropdownBtn to="/staff-users" Icon={Security16}>Users</DropdownBtn>
                        <br />
                        <DropdownBtn to={getReportLink("SystemLogsReport")} Icon={Archive16}>System Logs</DropdownBtn>
                        <DropdownBtn to="/system-settings" Icon={Settings16}>System Settings</DropdownBtn>
                    </div>
                </div>
            )
        },
    ]
}